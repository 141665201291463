<template>
  <div id="infrastructure">
    <div class="heading text-6xl font-semibold">Infrastructure & Thematic Holdings</div>
    <img class="mt-20" src="../../assets/images/thematic-holdings.png" alt="" />

    <div class="infrastructure_body text-justify">
      <div class="sub_headings text-5xl my-10"><b>Infrastructure</b> Fund</div>
      <div class="text-xl desc">
        <p>
          Infrastructure investment plays a key role in driving economic development,
          transformation and growth, rapid industrialisation which ultimately leads to
          creation of jobs. Nasarawa state has an active and increasing infrastructure
          project portfolio that it expects to execute through Public-Private Partnership
          (PPP) arrangements over the next 10 years. The cost of completing these projects
          is projected to be in the range of ₦50 billion
        </p>
        <p>
          Hence, marrying the urgency of bridging the State’s infrastructural deficit,
          with the necessity to enhance private sector led participation within the
          States’ financing activities, the Act of the Nasarawa Investment Development
          Agency (NASIDA) provides for the establishment of an infrastructure fund, the
          Nasarawa State Infrastructure Fund (NSIF).
        </p>
        <p>The objectives of the fund include:</p>

        <p>1. De-risking infrastructure investment projects within the State.</p>
        <p>
          2. Acquiring bonds, equity, loans, and other securities issued by private
          parties and financial institutions for deployment across identified
          opportunities within the State.
        </p>
        <p>
          3. Re-investing the profits accrued back into the fund and distributing profits
          to its shareholders.
        </p>

        <p>
          The NSIF seeks to maximize economic impact by investing in commercially viable
          infrastructure projects. It will be funded from a fixed percentage of the State
          Government’s Internally Generated Revenue (IGR) and private sector investors
          (accredited investors, High Net worth Individuals and Institutions) as approved
          by the Board. The fund managers constitute a team of investment professionals
          tasked with identifying infrastructure investments, undertaking project
          development for potential investments, recommending projects for investment, and
          submitting progress reports to the Board.
        </p>
      </div>
      <div>
        <div class="sub_headings text-5xl my-10"><b>Thematic</b> Holding Companies</div>
        <p class="text-xl">
          NASIDA oversees the management of the State Thematic holdong companies for
          participating in private-sector investments
        </p>
      </div>

      <div class="cards">
        <div class="card">
          <img src="../../assets/images/co-img.png" alt="" />
          <div class="card_desc">
            <div>
              <h1 class="text-6xl mb-10">MinCo</h1>
              <p>
                Developing a public-private sector participatory model for mining value
                chain advancement and viability
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <img src="../../assets/images/co-img.png" alt="" />
          <div class="card_desc">
            <div>
              <h1 class="text-6xl mb-10">AgroCo</h1>
              <p>
                Creating a sustainable public-private sector participatory approach for
                improvement and sustainability in the agricultural value chain
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <img src="../../assets/images/co-img.png" alt="" />
          <div class="card_desc">
            <div>
              <h1 class="text-6xl mb-10">InfraCo</h1>
              <p>
                Created solely to provide an enabling environment and derisk projects for
                private sector participation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./infrastructure.page.js"></script>
<style src="./infrastructure.page.css" scoped lang="css"></style>
